<template>
  <v-autocomplete
    outlined
    :items="items"
    :label="field.text"
    v-model="value"
    :rules="validators"
    placeholder="-"
    append-icon="mdi-chevron-down"
    dense
    :clearable="field.clearable"
    @click:clear="clearValue"
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    ...mapState({
      filters: state => state.core.filters
    }),
    items () {
      return this.filters[this.field.options]
    },
    value: {
      get () {
        return get(this.data, this.field.value, '')
      },
      set (value) {
        this.$emit('update', { [this.field.name]: value })
      }
    }
  },
  methods: {
    clearValue () {
      this.$nextTick(() => { this.value = null })
    }
  }
}
</script>
